import { Box, CssBaseline, Drawer, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import { useTranslation } from "react-i18next";

const drawerWidth = "20%";
export default function SideNavigation() {
  const [t, i18n] = useTranslation("global");
  const menuList = [
    t("nav.t1"),
    t("nav.t2"),
    t("nav.t3"),
    t("nav.t9"),
    t("nav.t4"),
    t("nav.t5"),
    t("nav.t6"),
    t("nav.t8"),
    t("nav.t10"),
    t("nav.t7"),
  ];
  const getRoute = (text: string): string => {
    switch (text) {
      case t("nav.t1"):
        return "/home";
      case t("nav.t2"):
        return "/manage-admin";
      case t("nav.t3"):
        return "/manage-tenant";
      case t("nav.t9"):
        return "/manage-users";
      case t("nav.t4"):
        return "/manage-category";
      case t("nav.t5"):
        return "/survey";
      case t("nav.t6"):
        return "/game";
      case t("nav.t8"):
        return "/breef-setting";
      case t("nav.t10"):
        return "/logs";
      case t("nav.t7"):
        return "/logout";
      default:
        console.log("inside default ", text);
        return "/home";
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            alignItems: "center",
            borderTop: "1px solid grey",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <img src={Logo} alt="Logo" style={{ width: "100%" }} />
        </Toolbar>
        <List>
          {menuList.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ margin: 2 }}>
              <Link to={getRoute(text)} style={{ textDecoration: "none", color: "inherit" }}>
                <ListItemText primary={text} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
