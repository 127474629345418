import { createSlice } from "@reduxjs/toolkit";

interface Result {
  inviteLinkSmsContent: string;
  _id: string;
}

interface ApiResponse {
  results: Result[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const InitialValues: ApiResponse = {
  results: [],
  page: 0,
  limit: 0,
  totalPages: 0,
  totalResults: 0,
};
const InitialState = {
  smsSetting: InitialValues,
};

export const SmsSettingSlice = createSlice({
  name: "SmsSetting",
  initialState: InitialState,
  reducers: {
    setSmsSettingData: (state, action) => {
      state.smsSetting = action.payload;
    },
  },
});

export const { setSmsSettingData } = SmsSettingSlice.actions;
export default SmsSettingSlice.reducer;
