import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import * as Yup from "yup";
import { forgetPassword, loginUser, resetPassword } from "../service/AuthenticationService";
import { updateBreef, updateScoreDetails } from "../service/BreefService";
import { addNewCategory, updateCategory } from "../service/CategoryService";
import { createGame } from "../service/GameService";
import { addUser, updateUser, updateUserOrInvitedUser } from "../service/UserService";
import { showErrorToast, showSuccessToast } from "./Toast";
import { updateTeamName } from "../service/teamService";
import { CreateBreefSetting, updateBreefSetting } from "../service/BreefSettingService";
import {
  addNewSmsContent,
  updateReminderSmsContent,
  updateSmsContent,
} from "../service/SmsSettingService";

interface SignInFormValues {
  email: string;
  password: string;
}
export interface ForgetPasswordValues {
  email: string;
}
export interface ResetPasswordValues {
  newPassword: string;
}
interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
}
export interface AddAdminValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  role: string;
}
interface AddTenantsValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  role: string;
}

export interface UpdateUserValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  totalScore: string;
}

export interface AddBreefSettingValues {
  startScore: number;
  pointsPerSecond: number;
  pointsPerRightAnswer: number;
  pointsPerWrongAnswer: number;
  pointsMultiplier: number;
}

export interface AddCategoryValues {
  categoryName: string;
}

export interface AddSmsSettingValues {
  inviteLinkSmsContent: string;
}

export interface AddReminderSmsSettingValues {
  reminderSmsContent: string;
}

export interface Question {
  question: string;
  options: string[];
  answer: number;
}
export interface AddBreefValues {
  period: string;
  expiry: string;
  breefName: string;
  videoUrl: string;
  questions: Question[];
  tenantId: string;
  status: string;
  gameId: string;
  tenantName?: string;
  gameName?: string;
  answer?: number;
}

export interface AddTeamValues {
  teamName: string;
  tenantId: string;
}
interface Game {
  gameName: string;
  categoryId: string;
  gameCode: string;
  gameDescription: string;
}

export interface UpdateBreefDetailsValues {
  breefName: string;
}

export interface UpdateScoreValues {
  breefScore: number;
  quizScore: number;
}

const signInSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and contain at least one alphanumeric character"
    )
    .required("Password is required"),
});
const signUpFormSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and contain at least one alphanumeric character"
    )
    .required("Password is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNo: Yup.string().required("Phone number is required"),
});
const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Email is required"),
});
const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and contain at least one alphanumeric character"
    )
    .required("Password is required"),
});
const AddAdminSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNo: Yup.string().required("Phone number is required"),
  role: Yup.string().required("Role is required"),
});

const UpdateAdminSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and contain at least one alphanumeric character"
    )
    .required("Password is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNo: Yup.string().required("Phone number is required"),
});
const AddTenantSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and contain at least one alphanumeric character"
    )
    .required("Password is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNo: Yup.string().required("Phone number is required"),
});

const UpdateUserSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Please provide a valid email").nullable(),
  phoneNo: Yup.string().nullable(),
});
const AddBreefSettingSchema = Yup.object().shape({
  startScore: Yup.number().required("startScore is required"),
  pointsPerSecond: Yup.number().required("pointsPerSecond is required"),
  pointsPerRightAnswer: Yup.number().required("First name is required"),
  pointsPerWrongAnswer: Yup.number().required("Last name is required"),
  pointsMultiplier: Yup.number().required("Phone number is required"),
});

const AddBreefSchema = Yup.object().shape({
  breefName: Yup.string().required("Breef Name is required"),
  videoUrl: Yup.string().required("Video Url is required"),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        options: Yup.array()
          .of(Yup.string().required("Option is required"))
          .min(4, "At least 4 options are required"),
        answer: Yup.string().required("Answer is required"),
      })
    )
    .min(1, "At least one question is required"),
  tenantId: Yup.string().required("Tenant ID is required"),
  status: Yup.string().required("Status is required"),
});

const AddTeamSchema = Yup.object().shape({
  teamName: Yup.string().required("Team Name is required"),
});

const AddCategoriesSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category is required"),
});

const AddSmsSettingSchema = Yup.object().shape({
  inviteLinkSmsContent: Yup.string().required("SmS Content is required"),
});

const AddReminderSmsSettingSchema = Yup.object().shape({
  reminderSmsContent: Yup.string().required("SmS Content is required"),
});

const AddGameSchema = Yup.object().shape({
  gameName: Yup.string().required("Game name is required"),
  categoryId: Yup.string().required("Category id is required"),
  gameCode: Yup.string().required("Game code is required"),
});

const UpdateBreefSchema = Yup.object().shape({
  breefName: Yup.string().required("Breef name is required"),
});

const UpdateScoreSchema = Yup.object().shape({
  breefScore: Yup.number().required("Breef score is required"),
  quizScore: Yup.number().required("Quiz score is required"),
});
export const useSignInForm = (dispatch: Dispatch, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<SignInFormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      try {
        const response = await loginUser(values, dispatch, isMobile);
        if (
          response.status >= 200 &&
          response.status <= 301 &&
          response?.data?.user?.role === "admin"
        ) {
          showSuccessToast("Login Successful", isMobile);
          navigate("/home");
        }
      } catch (error: any) {
        console.log("response is ", error);
      }
    },
  });
  return formik;
};

export const useSignUpForm = () => {
  const formik = useFormik<SignUpFormValues>({
    initialValues: {
      email: "",
      password: "",
      phoneNo: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: signUpFormSchema,
    onSubmit: async (values) => {
      console.log("Values are ", values);
      try {
        const response = {
          data: "SignUp",
          status: 200,
        };
        if (response.status >= 200 && response.status <= 301) {
          console.log("SignUp response: " + JSON.stringify(response));
        }
      } catch (error: any) {
        console.error("SignUp error:", error);
      }
    },
  });
  return formik;
};

export const useForgetPasswordForm = (isMobile: boolean) => {
  const formik = useFormik<ForgetPasswordValues>({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      console.log("Values are ", values);
      try {
        const response = await forgetPassword(values, isMobile);
        if (response) {
          if (response.status >= 200 && response.status <= 301) {
            showSuccessToast("Forget password email sent.", isMobile);
          }
        }
      } catch (error: any) {
        console.error("Forget password error:", error);
      }
    },
  });
  return formik;
};

export const useResetPasswordForm = (isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<ResetPasswordValues>({
    initialValues: {
      newPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      console.log("Values are ", values);
      try {
        const response = await resetPassword(values, isMobile);
        if (response) {
          if (response.status >= 200 && response.status <= 301) {
            localStorage.removeItem("token");
            navigate("/");
          }
        }
      } catch (error: any) {
        console.error("reset password error:", error);
      }
    },
  });
  return formik;
};
export const useAddAdminForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddAdminValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      role: "",
      email: "",
      phoneNo: "",
    },
    validationSchema: isEdit ? UpdateAdminSchema : AddAdminSchema,
    onSubmit: async (values): Promise<any> => {
      try {
        let response;
        if (isEdit) {
          response = await updateUser(values, id, isMobile, navigate);
        } else {
          response = await addUser(values, isMobile, navigate);
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit ? "User updated successfully" : "User Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
      } catch (error: any) {
        console.error("Add admin error:", error);
      }
    },
  });
  return formik;
};
export const useAddTenantForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddTenantsValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNo: "",
      role: "",
    },
    validationSchema: AddTenantSchema,
    onSubmit: async (values) => {
      values.role = "tenant";
      let response;
      console.log("Entered atleast", isEdit);

      try {
        if (isEdit) {
          console.log("Updating ser to true");

          response = await updateUser(values, id, isMobile, navigate);
          formik.resetForm();
        } else {
          response = await addUser(values, isMobile, navigate);
          formik.resetForm();
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit ? "Tenant updated successfully" : "Tenant Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
      } catch (error: any) {
        console.error("Add admin error:", error);
      }
    },
  });
  return formik;
};

export const useAddBreefSettingForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddBreefSettingValues>({
    initialValues: {
      startScore: 0,
      pointsPerSecond: 0,
      pointsPerRightAnswer: 0,
      pointsPerWrongAnswer: 0,
      pointsMultiplier: 0,
    },
    validationSchema: AddBreefSettingSchema,
    onSubmit: async (values) => {
      // values.role = "tenant";
      let response;
      console.log("Entered atleast", isEdit);

      try {
        if (isEdit) {
          console.log("Updating ser to true");

          response = await updateBreefSetting(values, id);
          formik.resetForm();
        } else {
          response = await CreateBreefSetting(values, isMobile, navigate);
          formik.resetForm();
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit
            ? "Breef setting updated successfully"
            : "Breef Setting Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
      } catch (error: any) {
        console.error("Add admin error:", error);
      }
    },
  });
  return formik;
};

export const useAddCategoryForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddCategoryValues>({
    initialValues: {
      categoryName: "",
    },
    validationSchema: AddCategoriesSchema,
    onSubmit: async (values) => {
      try {
        let response;
        if (isEdit) {
          response = await updateCategory(values, id, isMobile, navigate);
        } else {
          response = await addNewCategory(values, isMobile, navigate);
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit
            ? "Category updated successfully"
            : "Category Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
        formik.resetForm();
      } catch (error: any) {
        console.error("Add category error:", error);
      }
    },
  });
  return formik;
};

export const useAddSmsSettingForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddSmsSettingValues>({
    initialValues: {
      inviteLinkSmsContent: "",
    },
    validationSchema: AddSmsSettingSchema,
    onSubmit: async (values) => {
      try {
        console.log("here it submit the data");

        let response;
        if (isEdit) {
          response = await updateSmsContent(values, id, isMobile, navigate);
        } else {
          response = await addNewSmsContent(values, isMobile, navigate);
        }
        if ((response.status >= 200 && response.status <= 301) || response?.inviteLinkSmsContent) {
          const errorMessage = isEdit
            ? "Sms Setting updated successfully"
            : "Sms Setting Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
        formik.resetForm();
        return response;
      } catch (error: any) {
        console.error("Add category error:", error);
      }
    },
  });
  return formik;
};

export const useAddSmsReminderSettingForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddReminderSmsSettingValues>({
    initialValues: {
      reminderSmsContent: "",
    },
    validationSchema: AddReminderSmsSettingSchema,
    onSubmit: async (values) => {
      try {
        console.log("here it submit the data");

        let response;
        if (isEdit) {
          response = await updateReminderSmsContent(values, id, isMobile, navigate);
        } else {
          // response = await addNewSmsContent(values, isMobile, navigate);
        }
        if ((response.status >= 200 && response.status <= 301) || response?.inviteLinkSmsContent) {
          const errorMessage = isEdit
            ? "Reminder Sms Setting updated successfully"
            : "Reminder Sms Setting Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
        formik.resetForm();
        return response;
      } catch (error: any) {
        console.error("Add category error:", error);
      }
    },
  });
  return formik;
};

export const useAddBreefForm = (id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<AddBreefValues>({
    initialValues: {
      breefName: "",
      videoUrl: "",
      gameId: "",
      period: "",
      expiry: "",
      questions: [
        {
          question: "",
          options: ["", "", "", ""],
          answer: 0,
        },
      ],
      tenantId: "",
      status: "",
    },
    validationSchema: AddBreefSchema,
    onSubmit: async (values) => {
      try {
        console.log("Entered editing mode");

        const response = await updateBreef(values, id, isMobile, navigate);
        if (response.status >= 200 && response.status <= 301) {
          console.log("Add breef response: " + JSON.stringify(response));
          formik.resetForm();
          showSuccessToast("Breef updated successfully.", isMobile);
          navigate("/manage-breef");
        }
      } catch (error: any) {
        console.error("Add breef error:", error);
      }
    },
  });
  return formik;
};

export const useAddTeamForm = (id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  console.log("updateTeamView:formik:id", id);

  const formik = useFormik<AddTeamValues>({
    initialValues: {
      tenantId: "",
      teamName: "",
    },
    validationSchema: AddTeamSchema,
    onSubmit: async (values: any) => {
      try {
        console.log("Entered editing mode");

        const response = await updateTeamName(values, id, isMobile, navigate);
        if (response.status >= 200 && response.status <= 301) {
          console.log("Add teamName response: " + JSON.stringify(response));
        }
        // formik.resetForm();
        showSuccessToast("teamName updated successfully.", isMobile);
        // navigate("/manage-tenant");
      } catch (error: any) {
        console.error("Add TeamName error:", error);
      }
    },
  });
  return formik;
};

export const useAddGameForm = (isMobile: boolean, id?: string) => {
  const navigate = useNavigate();
  const formik = useFormik<Game>({
    initialValues: {
      categoryId: "",
      gameCode: "",
      gameName: "",
      gameDescription: "",
    },
    validationSchema: AddGameSchema,
    onSubmit: async (values) => {
      try {
        const response = await createGame(values, isMobile, navigate);
        if (response?.status === 201 && response.statusText === "Created") {
          showSuccessToast("Game added successfully", isMobile);
          formik.resetForm();
        }
      } catch (error: any) {
        console.error("Add breef error:", error);
      }
    },
  });
  return formik;
};

export const useUpdateScoreForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<UpdateScoreValues>({
    initialValues: {
      breefScore: 0,
      quizScore: 0,
    },
    validationSchema: UpdateScoreSchema,
    onSubmit: async (values) => {
      let response;
      console.log("Score form ---", id);

      try {
        if (isEdit) {
          console.log("Updating ser to true");

          response = await updateScoreDetails(values, id, isMobile, navigate);
          // formik.resetForm();
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit ? "Score updated successfully" : "Score Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
      } catch (error: any) {
        console.error("Score update error:", error);
      }
    },
  });
  return formik;
};

export const useUpdateUserForm = (isEdit: boolean, id: string, isMobile: boolean) => {
  const navigate = useNavigate();
  const formik = useFormik<UpdateUserValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNo: "",
      totalScore: "0",
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values) => {
      let response;
      console.log("Entered atleast", isEdit);

      try {
        if (isEdit) {
          console.log("Updating ser to true");

          response = await updateUserOrInvitedUser(values, id, isMobile, navigate);
          // formik.resetForm();
        }
        if (response.status >= 200 && response.status <= 301) {
          const errorMessage = isEdit ? "User updated successfully" : "User Added successfully";
          showSuccessToast(errorMessage, isMobile);
        }
      } catch (error: any) {
        console.error("Add User error:", error);
      }
    },
  });
  return formik;
};
