import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    useMediaQuery,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import EditIcon from "@mui/icons-material/Edit";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { useAppDispatch, useAppSelector } from "../../redux";
  import { AppDispatch } from "../../redux/store";
  import { deleteBreef } from "../../service/BreefService";
  import { getTenant } from "../../service/TenantService";
  import { getGame } from "../../service/GameService";
  import { useAddBreefForm } from "../../utils/forms";
  import { useTranslation } from "react-i18next";
  import { DataObject } from "@mui/icons-material";
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  
  const TableWrapper = styled(TableContainer)({
    margin: "auto",
    marginTop: "1%",
    width: "80%",
  }) as typeof TableContainer;
  
  export interface Result {
    period: string;
    breefName: string;
    videoUrl: string;
    breefScore: number;
    quizScore: number;
    status: string;
    expiry: string;
  breefId: string;
  scoreId: string;
  }
  
  interface BreefTableProps {
    results: Result[];
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  }
  
  const BreefTable: React.FC<BreefTableProps> = ({ results, page, limit, totalPages }) => {
    const [t] = useTranslation("global");
    const [id, setId] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const isMobile = useMediaQuery("(max-width:600px)");
    const formik = useAddBreefForm(id, isMobile);
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
  
    const handleEditClick = async (breefId: string, scoreId: string,breefName: string,quizScore: number,breefScore: number) => {
      setIsEditing(true);
      setId(breefId);
      if (results) {
        const record = results.find((result) => result.breefId === breefId);
        if (record) {
     
        }
      }
      localStorage.setItem("breefId", breefId);
      localStorage.setItem("scoreId", scoreId);
      localStorage.setItem("breefName", breefName);
      localStorage.setItem("quizScore", quizScore.toString());
      localStorage.setItem("breefScore", breefScore.toString());
      navigate(`/breefAndScore?isEdit=true`);
    };
  
    const handleDeleteClick = (breefId: string) => {
      setId(breefId);
      setIsDeleteModal(true);
    };
  
    const handleDeleteBreef = async () => {
      setIsDeleteModal(false);
      await deleteBreef(id, navigate, isMobile);
      setIsDelete(true);
    };
  
    const handleCancelDelete = () => {
      setIsDeleteModal(false);
    };
  
    return (
      <>
        <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
          <DialogTitle>{t("breeftable.t1")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("breeftable.t2")}</DialogContentText>
          </DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
            <Button onClick={handleCancelDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
              {t("breeftable.t3")}
            </Button>
            <Button onClick={handleDeleteBreef} color="error" variant="contained">
              {t("breeftable.t4")}
            </Button>
          </Box>
        </Dialog>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t5")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t6")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t7")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t10")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t11")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t9")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="left">
                  {t("breeftable.t8")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((breef) => {
                const satrtDate = new Date(breef?.period);
                const endDate = new Date(breef?.expiry);
                const satrtDateString = `${satrtDate.getDate().toString().padStart(2, "0")}/${(
                  satrtDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${satrtDate.getFullYear()}`;
                const endDateString = `${endDate.getDate().toString().padStart(2, "0")}/${(
                  endDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${endDate.getFullYear()}`;
                return (
                  <StyledTableRow key={breef.breefId}>
                    <TableCell align="left">{breef.breefName}</TableCell>
                    <TableCell align="left">{breef.videoUrl}</TableCell>
                    <TableCell align="left"> {`${satrtDateString} - ${endDateString}`}</TableCell>
                    <TableCell align="left">{breef.breefScore}</TableCell>
                    <TableCell align="left">{breef.quizScore}</TableCell>
                    <TableCell align="left">{breef.status}</TableCell>
                    <TableCell align="left">
                      {/* <IconButton aria-label="view" onClick={() => handleViewClick(breef?.breefId)}>
                        <VisibilityIcon />
                      </IconButton> */}
                      <IconButton aria-label="edit" onClick={() => handleEditClick(breef?.breefId,breef?.scoreId,breef?.breefName,breef?.quizScore,breef?.breefScore)}>
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton aria-label="delete" onClick={() => handleDeleteClick(breef?.breefId)}>
                        <DeleteIcon />
                      </IconButton> */}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </>
    );
  };
  
  export default BreefTable;
  