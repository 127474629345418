import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { AppDispatch } from "../../redux/store";
import { useUpdateScoreForm } from "../../utils/forms";
import { useTranslation } from "react-i18next";

export interface Result {
  breefName: string;
  breefScore: number;
  quizScore: number;
  breefId: string;
  scoreId: string;
}

const UpdateBreefAndScore = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { userId, record } = location.state || "";
  const [breefName, setBreefName] = useState<string|null>('');
  const [scoreId, setScoreId] = useState<string|null>('');
  const isEdit = new URLSearchParams(location.search).get("isEdit");
  const isMobile = useMediaQuery("(max-width:600px)");
  const scoreFormik = useUpdateScoreForm(!!isEdit, scoreId || "", isMobile);
  const [isEditing, setIsEditing] = useState(isEdit === "true");
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const fetchData = async () => {
    const breefName = localStorage.getItem("breefName");
    const quizScore = localStorage.getItem("quizScore");
    const breefScore = localStorage.getItem("breefScore");
    const scoreId = localStorage.getItem("scoreId");

    if (breefName) {
        console.log("seeting breef name--", breefName);
        
    
      setBreefName(breefName);
    }
    if (quizScore && breefScore) {
      scoreFormik.setValues({
        quizScore: parseInt(quizScore),
        breefScore: parseInt(breefScore),
      });
      setScoreId(scoreId);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("handling submit");
    
    try {
      scoreFormik.handleSubmit();
    } catch (err) {
      console.error("Error editing user details: ", err);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    navigate(-1);
  };
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginRight: "10%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Button onClick={handleCancel} variant="outlined" sx={{ minWidth: "1%", marginLeft: "1%" }}>
          {"<"}
        </Button>
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ display: "flex", alignItems: "center", marginLeft: "6%" }}
        >
          {isEdit === "true" ? "Update " : "View "}
          {t("breefUpdate.t6")}
        </Typography>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column", marginX: "10%", marginBottom: "5%" }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="breefName"
                name="breefName"
                label={t("breefUpdate.t1")}
                type="text"
                fullWidth
                variant="outlined"
                value={breefName}
                disabled={isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="breefScore"
                name="breefScore"
                label={t("breefUpdate.t2")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={scoreFormik.handleChange("breefScore")}
                value={scoreFormik.values.breefScore}
                error={scoreFormik.touched.breefScore && Boolean(scoreFormik.errors.breefScore)}
                helperText={scoreFormik.touched.breefScore && scoreFormik.errors.breefScore}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="quizScore"
                name="quizScore"
                label={t("breefUpdate.t3")}
                type="quizScore"
                fullWidth
                variant="outlined"
                onChange={scoreFormik.handleChange("quizScore")}
                value={scoreFormik.values.quizScore}
                error={scoreFormik.touched.quizScore && Boolean(scoreFormik.errors.quizScore)}
                helperText={scoreFormik.touched.quizScore && scoreFormik.errors.quizScore}
                // disabled
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label={t("breefUpdate.t4")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("phoneNo")}
                value={formik.values.phoneNo}
                error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                disabled
              />
            </Grid> */}
          </Grid>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            {isEditing && (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  sx={{
                    mr: 1,
                    color: "#ED670A",
                    borderColor: "#ED670A",
                    "&:hover": { borderColor: "#d55a07" },
                  }}
                >
                  {t("updateTenant.t6")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                  {t("updateTenant.t7")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default UpdateBreefAndScore;
