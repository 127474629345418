import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { deleteUser, getAllUsers } from "../../service/UserService";
import { useAddTenantForm } from "../../utils/forms";
import { setTenantInfo } from "../../redux/slices/TenantSlice";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../../utils/UseDebounce";

interface Team {
  teamName: string;
  tenantId: string;
  id: string;
}

interface User {
  isRegistered: boolean;
  id: string;
  firstName: string;
  totalScore:string;
  lastName: string;
  email: string;
  phoneNumber: string;
  teamId: Team;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

export default function ManageUsers() {

  const [userData, setUserData] = useState<User[]>([]);
  const [userResponse, setUserResponse] = useState<any>();
  const [isDisabledEditing, setIsDisabledEditing] = useState(false);
  const [titleText, setTitleText] = useState("Add New Tenant");
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [id, setId] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = useState(0); // Page number starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [query, setQuery] = useState("");
  const debounceQuery = useDebounce(query, 1000);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchUsers(newPage, rowsPerPage); // Fetch games when page changes
  };

  useEffect(() => {
    if (debounceQuery || debounceQuery === "") {
      fetchUsers(page, rowsPerPage, debounceQuery);
    }
    console.log("i am using devouncing");
  }, [page, rowsPerPage, debounceQuery]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchUsers(0, parseInt(event.target.value, 10)); // Fetch games when rows-per-page changes
  };
  const handleViewClick = (userId: string) => {
    console.log(`View clicked for user with ID ${userId}`);
    setIsView(true);
    setIsDisabledEditing(true);
    console.log("user data 12e23e23: ", userData);

    const record = userData.find((item) => item.id === userId);

    console.log("record: ", record);
    navigate(`/user/${userId}?isEdit=false`, { state: { userId, record } });
  };

  const handleEditClick = (userId: string) => {
    console.log(`Edit clicked for user with ID ${userId}`);
    setIsEditing(true);
    setId(userId);
    // filterBreefsForUsers(userId);
    const record = userData.find((item) => item.id === userId);
    console.log("record : ", record);
    navigate(`/user/${userId}?isEdit=true`, { state: { userId, record } });
  };

  useEffect(() => {
    isView ? setTitleText("View Tenant") : setTitleText("Edit Tenant");
    console.log("userData for manage--", userData);
  }, [isEditing, isView, userData]);

  const fetchUsers = async (page: number = 0, limit: number = rowsPerPage, query?: string) => {
    const fetchedUsers = await getAllUsers(dispatch, isMobile, navigate, limit, page + 1, query);
    if (fetchedUsers) {
      console.log("Fetched tenants: ", fetchedUsers);
      setUserResponse(fetchedUsers);
      setUserData(fetchedUsers.results);
      setTenantInfo(fetchedUsers);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  const handleDeleteTenant = async () => {
    setIsDeleteModal(false);
    await deleteUser(id, isMobile, navigate);
    fetchUsers();
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "2%",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {t("manageUsers.title")}
        </Typography>
        {!isEditing || isView ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          ></Box>
        ) : (
          <></>
        )}
      </div>

      {isEditing || isView ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginX: "10%",
            marginBottom: "5%",
          }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
            <DialogTitle>{t("managetenant.t14")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("managetenant.t15")}</DialogContentText>
            </DialogContent>
            <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
              <Button
                onClick={handleCancelDelete}
                color="primary"
                variant="outlined"
                sx={{ mr: 1 }}
              >
                {t("managetenant.t16")}
              </Button>
              <Button onClick={handleDeleteTenant} color="error" variant="contained">
                {t("managetenant.t17")}
              </Button>
            </Box>
          </Dialog>
          <TableWrapper component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t18")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t19")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t21")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("manageUsers.t13")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("manageUsers.t12")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t22")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user) => (
                  <StyledTableRow key={user.id}>
                    <TableCell align="center">{user.firstName + " " + user.lastName}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.phoneNumber}</TableCell>
                    <TableCell align="center">{user.totalScore}</TableCell>
                    <TableCell align="center">{user.isRegistered + ""}</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="view" onClick={() => handleViewClick(user.id)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="edit" onClick={() => handleEditClick(user.id)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {userResponse && (
              <TablePagination
                component="div"
                count={userResponse.totalResults || 0} // Total number of games
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          </TableWrapper>
        </Box>
      )}
    </Layout>
  );
}
