import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/authentication/SignIn";
import ForgetPassword from "./pages/authentication/ForgetPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import ManageAdmin from "./pages/manageRoles/ManageAdmin";
import ManageTenants from "./pages/manageRoles/ManageTenants";
import ManageCategory from "./pages/manageRoles/ManageCategory";
import ManageBreef from "./pages/manageBreef/ManageBreef";
import Home from "./pages/home/Home";
import ProtectedRoutes from "./protectedRoutes";
import NotFound from "./utils/NotFound";
import Logout from "./pages/home/Logout";
import UpdateViewBreef from "./pages/manageBreef/UpdateViewBreef";
import UpdateViewEditTenant from "./pages/manageRoles/UpdateViewEditTenant";
import Game from "./pages/game/Game";
import UpdateViewGame from "./pages/game/UpdateViewGame";
import UpdateViewTeam from './manageTeam/UpdateViewTeam'
import Survey from "./pages/unsubscription/Survey";
import UpdateBreefSetting from "./pages/setting/UpdateBreefSetting";
import AwsLogs from "./pages/logs/AwsLogs";
import ManageUsers from "./pages/manageUsers/ManageUsers";
import UpdateViewEditUsers from "./pages/manageUsers/UpdateViewEditUser";
import UpdateBreefAndScore from "./pages/manageUsers/UpdateBreefAndScore";

export default function Router() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/manage-admin" element={<ManageAdmin />} />
          <Route path="/manage-tenant" element={<ManageTenants />} />
          <Route path="/manage-users" element={<ManageUsers/>} />
          <Route path="/manage-category" element={<ManageCategory />} />
          <Route path="/manage-breef" element={<ManageBreef />} />
          <Route path="/home" element={<Home />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/breef/:breefId" element={<UpdateViewBreef />} />
          <Route path="/team/:teamId" element={<UpdateViewTeam />} />
          <Route path="/tenant/:tenantId" element={<UpdateViewEditTenant />} />
          <Route path="/user/:userId" element={<UpdateViewEditUsers/>} />
          
          <Route path="/breefAndScore/" element={<UpdateBreefAndScore/>} />
          <Route path="/game" element={<Game />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/logs" element={<AwsLogs />} />
          <Route path="/breef-setting" element={<UpdateBreefSetting />} />
          <Route path="/game/:gameId" element={<UpdateViewGame />} />
        </Route>
        {/* <Route path='/sign-up' element={<SignUp />} /> */}
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}
