import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { deleteUser, getAllAdminUsers, getTenantUsers } from "../../service/UserService";
import { useAddTenantForm } from "../../utils/forms";
import { setTenantInfo } from "../../redux/slices/TenantSlice";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../../utils/UseDebounce";

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  id: string;
}

interface Team {
  teamName: string;
  tenantId: string;
  id: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  tenantId: Tenant | null;
  isEmailVerified: boolean;
  teamId: Team;
}

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  teamId?: string;
  id: string;
}

interface Game {
  id: string;
  status: string;
  gameName: string;
  categoryId: string;
}

interface Question {
  question: string;
  options: string[];
  answer: number;
  _id: string;
}

interface Result {
  breefName: string;
  videoUrl: string;
  questions: Question[];
  tenantId: Tenant;
  gameId: Game;
  status: string;
  expiry: string;
  id: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

const Title = styled(Typography)({
  marginBottom: "16px",
});

export default function ManageTenants() {
  const users = [
    {
      id: "1",
      name: "abc fdsv",
      email: "csd@g.com",
      role: "Tenant",
      phoneNo: "123456789",
      tenantId: "1234567890",
      teamId: "12345",
    },
    {
      id: "2",
      name: "cnds jcios",
      email: "cdsc@g.com",
      role: "Tenant",
      phoneNo: "123456789",
      tenantId: "1234567890",
      teamId: "12345",
    },
  ];
  const [tenantData, setTenantData] = useState<User[]>([]);
  const [tenantResponse, setTenantResponse] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledEditing, setIsDisabledEditing] = useState(false);
  const [titleText, setTitleText] = useState("Add New Tenant");
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const role = ["tenant"];
  const token = localStorage.getItem("access_token");
  const [id, setId] = useState("");
  const [breefInfo, setBreefInfo] = useState();
  const dispatch: AppDispatch = useAppDispatch();
  const data = useAppSelector((state) => state.tenants);
  const breefData = useAppSelector((state) => state.breefs);
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddTenantForm(isEditing, id, isMobile);
  const [isDelete, setIsDelete] = useState(false);
  const [filteredData, setFilteredData] = useState<Result[]>();
  const [categoryData, setCategoryData] = useState<[]>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [userId, setUserId] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = useState(0); // Page number starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [query, setQuery] = useState("");
  const debounceQuery = useDebounce(query, 1000);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchTenants(newPage, rowsPerPage); // Fetch games when page changes
  };

  useEffect(() => {
    if (debounceQuery || debounceQuery === "") {
      fetchTenants(page, rowsPerPage, debounceQuery);
    }
    console.log("i am using devouncing");
  }, [page, rowsPerPage, debounceQuery]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchTenants(0, parseInt(event.target.value, 10)); // Fetch games when rows-per-page changes
  };

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
    setTitleText("Add New Tenant");
  };
  const handleViewClick = (userId: string) => {
    console.log(`View clicked for user with ID ${userId}`);
    setIsView(true);
    setIsDisabledEditing(true);
    console.log("Tenant data 12e23e23: ", tenantData);

    const record = tenantData.find((item) => item.id === userId);

    if (record) {
      formik.values.email = record.email;
      formik.values.phoneNo = record.phoneNumber;
      formik.values.role = record.role;
      formik.values.firstName = record.firstName;
      formik.values.lastName = record.lastName;
    }
    console.log("navigated now", formik.values.email);

    console.log("record: ", record);
    navigate(`/tenant/${userId}?isEdit=false`, { state: { userId, record } });
  };

  const handleEditClick = (userId: string) => {
    console.log(`Edit clicked for user with ID ${userId}`);
    setIsEditing(true);
    setId(userId);
    filterBreefsForTenant(userId);
    const record = tenantData.find((item) => item.id === userId);
    console.log("record : ", record);
    if (record) {
      formik.values.email = record.email;
      formik.values.phoneNo = record.phoneNumber;
      formik.values.role = record.role;
      formik.values.firstName = record.firstName;
      formik.values.lastName = record.lastName;
    }
    navigate(`/tenant/${userId}?isEdit=true`, { state: { userId, record } });
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setIsEditing(false);
    setIsView(false);
    setIsDisabledEditing(false);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
    //fetchTenantUsers(role);
    fetchTenants();
    setOpen(false);
  };

  useEffect(() => {
    isView ? setTitleText("View Tenant") : setTitleText("Edit Tenant");
  }, [isEditing, isView]);

  const fetchTenants = async (page: number = 0, limit: number = rowsPerPage, query?: string) => {
    const fetchedTenants = await getTenantUsers(
      dispatch,
      isMobile,
      navigate,
      limit,
      page + 1,
      query
    );
    if (fetchedTenants) {
      console.log("Fetched tenants: ", fetchedTenants);
      setTenantResponse(fetchedTenants);
      setTenantData(fetchedTenants.results);
      setTenantInfo(fetchedTenants);
    }
  };

  const filterBreefsForTenant = (tenantId: string) => {
    setFilteredData(
      breefData.breefData.results.filter((breef) => breef.tenantId.tenantId === tenantId)
    );
  };
  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  const handleDeleteTenant = async () => {
    setIsDeleteModal(false);
    await deleteUser(id, isMobile, navigate);
    fetchTenants();
  };

  const handleDeleteClick = (userId: string) => {
    setId(userId);
    setIsDeleteModal(true);
  };
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "2%",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {t("managetenant.t1")}
        </Typography>
        {!isEditing || isView ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              id="outlined-basic"
              label="Search"
              InputLabelProps={{
                sx: {
                  width: "100%",
                  marginTop: `${query ? '8px' : '0px'}`,
                  color: "rgba(0, 0, 0, 0.6)",
                  "&.Mui-focused": {
                    justifyContent: "left",
                    marginTop: "8px",

                    color: "rgba(0, 0, 0, 0.6)", // Change the focused label color to orange
                  },
                },
              }}
              sx={{
                width: "30%",

                "& .MuiInputBase-root": {
                  marginTop: "8px",
                  textAlign: "center",
                  height: "40px", // Adjust the height as needed
                  borderColor: "#ED670A",
                  padding: "0 12px", // Adjust the padding as needed
                },
                "& input": {
                  textAlign: "center", // Center the text within the input field itself
                  color: "rgba(0, 0, 0, 0.6)",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "#ED670A", // Add your desired border color here
                  },
                  "&:hover fieldset": {
                    borderColor: "#ED670A", // Add your desired hover border color here
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ED670A", // Add your desired focused border color here
                  },
                },
              }}
            ></TextField>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                alignSelf: "flex-end",
                backgroundColor: "#ED670A",
                "&:hover": { backgroundColor: "#d55a07" },
              }}
            >
              {t("managetenant.t2")}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </div>

      {isEditing || isView ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginX: "10%",
            marginBottom: "5%",
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="firstName"
                  name="firstName"
                  label={t("managetenant.t3")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={formik.handleChange("firstName")}
                  value={formik.values.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  disabled={isDisabledEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  margin="dense"
                  id="lastName"
                  name="lastName"
                  label={t("managetenant.t4")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={formik.handleChange("lastName")}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  disabled={isDisabledEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  margin="dense"
                  id="email"
                  name="email"
                  label={t("managetenant.t5")}
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={formik.handleChange("email")}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={isDisabledEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("managetenant.t6")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={formik.handleChange("phoneNo")}
                  value={formik.values.phoneNo}
                  error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  disabled={isDisabledEditing}
                />
              </Grid>
            </Grid>
            <TextField
              required
              margin="dense"
              id="password"
              name="password"
              label={t("managetenant.t7")}
              type="password"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange("password")}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={isDisabledEditing}
            />
            {filteredData &&
              filteredData.map((item) => (
                <Grid container spacing={2} key={item.id}>
                  <Grid item xs={12} sm={12}>
                    <label style={{ display: "block" }}>{t("managetenant.t31")}</label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      margin="dense"
                      id={`breefName-${item.id}`}
                      name={`breefName-${item.id}`}
                      label={t("managetenant.t8")}
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={item.breefName || ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="dense"
                      id={`status-${item.id}`}
                      name={`status-${item.id}`}
                      label={t("managetenant.t9")}
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={item.status || ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <label>
                      {t("managetenant.t10")}
                      <ReactPlayer
                        style={{ marginTop: "1%", marginBottom: "1%" }}
                        url={item.videoUrl}
                        width={"100%"}
                        loop={true}
                        controls={true}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <label style={{ display: "block" }}>{t("managetenant.t11")}</label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      margin="dense"
                      id={`gameName-${item.gameId.id}`}
                      name={`gameName-${item.gameId.gameName}`}
                      label={t("managetenant.t32")}
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={item.gameId.gameName || ""}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              ))}
            <DialogActions>
              <Button sx={{ color: "#ED670A" }} onClick={handleClose}>
                {t("managetenant.t12")}
              </Button>
              <Button sx={{ color: "#ED670A" }} type="submit">
                {t("managetenant.t13")}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
            <DialogTitle>{t("managetenant.t14")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("managetenant.t15")}</DialogContentText>
            </DialogContent>
            <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
              <Button
                onClick={handleCancelDelete}
                color="primary"
                variant="outlined"
                sx={{ mr: 1 }}
              >
                {t("managetenant.t16")}
              </Button>
              <Button onClick={handleDeleteTenant} color="error" variant="contained">
                {t("managetenant.t17")}
              </Button>
            </Box>
          </Dialog>
          <TableWrapper component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t18")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t19")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t20")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t21")}.
                  </StyledTableCell>
                  <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                    {t("managetenant.t22")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenantData.map((user) => (
                  <StyledTableRow key={user.id}>
                    <TableCell align="center">{user.firstName + " " + user.lastName}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.role}</TableCell>
                    <TableCell align="center">{user.phoneNumber}</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="view" onClick={() => handleViewClick(user.id)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="edit" onClick={() => handleEditClick(user.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => handleDeleteClick(user.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {tenantResponse && (
              <TablePagination
                component="div"
                count={tenantResponse.totalResults || 0} // Total number of games
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          </TableWrapper>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("managetenant.t23")}</DialogContentText>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="firstName"
              name="firstName"
              label={t("managetenant.t24")}
              type="text"
              fullWidth
              variant="standard"
              onChange={formik.handleChange("firstName")}
              value={formik.values.firstName}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              disabled={isDisabledEditing}
            />
            <TextField
              required
              margin="dense"
              id="lastName"
              name="lastName"
              label={t("managetenant.t25")}
              type="text"
              fullWidth
              variant="standard"
              onChange={formik.handleChange("lastName")}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              disabled={isDisabledEditing}
            />
            <TextField
              required
              margin="dense"
              id="email"
              name="email"
              label={t("managetenant.t26")}
              type="email"
              fullWidth
              variant="standard"
              onChange={formik.handleChange("email")}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={isDisabledEditing}
            />
            <TextField
              margin="dense"
              id="phoneNumber"
              name="phoneNumber"
              label={t("managetenant.t27")}
              type="text"
              fullWidth
              variant="standard"
              onChange={formik.handleChange("phoneNo")}
              value={formik.values.phoneNo}
              error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              disabled={isDisabledEditing}
            />
            <TextField
              required
              margin="dense"
              id="password"
              name="password"
              label={t("managetenant.t28")}
              type="password"
              fullWidth
              variant="standard"
              onChange={formik.handleChange("password")}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={isDisabledEditing}
            />
            <DialogActions>
              <Button sx={{ color: "#ED670A" }} onClick={handleClose}>
                {t("managetenant.t29")}
              </Button>
              <Button sx={{ color: "#ED670A" }} type="submit">
                {t("managetenant.t30")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}
