import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAddSmsReminderSettingForm, useAddSmsSettingForm } from "../../utils/forms";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllSmsSettings } from "../../service/SmsSettingService";

interface ChipData {
  linkSmsContent: string;
  reminderSmsContent: string;
  _id: string;
}
export default function SmsSetting() {
  const [data, setData] = React.useState<ChipData[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formikLinkSMS = useAddSmsSettingForm(isEdit, id, isMobile);
  const formikReminderSms = useAddSmsReminderSettingForm(isEdit, id, isMobile);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [isReminderEditing, setIsReminderEditing] = useState(false);
  const [t, i18n] = useTranslation("global");

  const handleCancel = () => {
    setIsEditing(false);
  };
  const handleReminderSmsCancel = () => {
    setIsReminderEditing(false);
  };

  const handleReminderSmsOpenEdit = () => {
    setIsReminderEditing(true);
  };
  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setId(data[0]?._id.toString());
    setIsEdit(true);
    e.preventDefault();
    console.log("Entered here", formikReminderSms.values);
    console.log("Entered here", isReminderEditing);

    if (isReminderEditing) {
      const reminderResponse = await formikReminderSms.submitForm();
      console.log("reminderResponse", reminderResponse);
    } else {
      const linkResponse = await formikLinkSMS.submitForm();
      console.log("linkResponse", linkResponse);
      // setIsLinkSmsEdit(false);
    }
    setIsReminderEditing(false);

    setIsEditing(false);
    setIsEdit(false);
  };

  useEffect(() => {
    if (isDelete) {
      setIsDelete(false);
    }
    fetchSmsSetting();
  }, [formikLinkSMS.isSubmitting, formikReminderSms.isSubmitting, isDelete]);
  const fetchSmsSetting = async () => {
    const response: any = await getAllSmsSettings(dispatch, isMobile, navigate);
    console.log("response inside fetch sms setting", response);
    setData(response);
    console.log("response inside fetch ", response?.[0].reminderSmsContent);

    formikLinkSMS.setValues({
      inviteLinkSmsContent: response?.[0].inviteLinkSmsContent || "",
    });
    formikReminderSms.setValues({
      reminderSmsContent: response?.[0].reminderSmsContent || "",
    });
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // marginRight: "30%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "6%",
          }}
        >
          {t("smsSetting.title")}
        </Typography>
      </div>
      <Typography
        variant="h5"
        fontWeight="bold"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "6%",
          marginBottom: "2%",
        }}
      >
        {t("smsSetting.linkSms")}
      </Typography>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginX: "10%",
            marginBottom: "5%",
            width: "100%",
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="inviteLinkSmsContent"
                  name="inviteLinkSmsContent"
                  label={t("smsSetting.textField")}
                  type="text"
                  fullWidth
                  multiline
                  minRows={3}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  onChange={formikLinkSMS.handleChange("inviteLinkSmsContent")}
                  value={formikLinkSMS.values.inviteLinkSmsContent}
                  error={
                    formikLinkSMS.touched.inviteLinkSmsContent &&
                    Boolean(formikLinkSMS.errors.inviteLinkSmsContent)
                  }
                  helperText={
                    formikLinkSMS.touched.inviteLinkSmsContent &&
                    formikLinkSMS.errors.inviteLinkSmsContent
                  }
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              {isEditing ? (
                <>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    sx={{
                      mr: 1,
                      color: "#ED670A",
                      borderColor: "#ED670A",
                      "&:hover": { borderColor: "#d55a07" },
                    }}
                  >
                    {t("updateTenant.t6")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                  >
                    {t("updateTenant.t7")}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleOpenEdit}
                  //  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                  {t("smsSetting.editLinkSms")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "2%",
            marginBottom: "1%",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ display: "flex", alignItems: "center", marginLeft: "6%" }}
          >
            {t("smsSetting.reminderSms")}
          </Typography>
        </div>

        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginX: "10%",
              marginBottom: "5%",
              width: "100%",
            }}
          >
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="reminderSmsContent"
                    name="reminderSmsContent"
                    label={t("smsSetting.textField")}
                    type="text"
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{ mt: 2 }}
                    variant="outlined"
                    onChange={formikReminderSms.handleChange("reminderSmsContent")}
                    value={formikReminderSms.values.reminderSmsContent}
                    error={
                      formikReminderSms.touched.reminderSmsContent &&
                      Boolean(formikReminderSms.errors.reminderSmsContent)
                    }
                    helperText={
                      formikReminderSms.touched.reminderSmsContent &&
                      formikReminderSms.errors.reminderSmsContent
                    }
                    disabled={!isReminderEditing}
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                {isReminderEditing ? (
                  <>
                    <Button
                      onClick={handleReminderSmsCancel}
                      variant="outlined"
                      sx={{
                        mr: 1,
                        color: "#ED670A",
                        borderColor: "#ED670A",
                        "&:hover": { borderColor: "#d55a07" },
                      }}
                    >
                      {t("updateTenant.t6")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                    >
                      {t("updateTenant.t7")}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={handleReminderSmsOpenEdit}
                    //  type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                  >
                    {t("smsSetting.editReminderSms")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
